import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import TableWrapper from 'components/Table'
import NoContent from 'components/NoContent'
import Menu from 'components/Menu'
import { columnsSpecialists } from 'data/columns'
import Delete from 'components/Modals/Delete'
import ModalWrapper from 'components/Modals/ModalWrapper'
import Button from 'components/Button'
import { palette } from 'styles/colors'
import Input from 'components/Input'
import { useFormik } from 'formik'
import { InputAdornment, IconButton } from '@material-ui/core'
import searchIcon from 'assets/trailing.png'
import Specialist from 'components/Modals/Specialist'
import ButtonCreate from 'components/ButtonCreate'
import { Close } from '@material-ui/icons'
import {
  getSpecialistsItems,
  getSpecialistsPagination,
  getIsLoadingSpecialists
} from 'redux/selectors/specialists'
import {
  createSpecialist as createSpecialistAction,
  editSpecialist as editSpecialistAction,
  deleteSpecialist as deleteSpecialistAction,
  uploadMedia,
  getSpecialists,
  deleteSpecialistSignature as deleteSpecialistSignatureAction,
  getRegions,
  getAllSpecialists
} from 'redux/modules/specialists/actions'
import { getUser } from 'redux/selectors/auth'
import ControlBlockExport from 'components/ControlBlockExport'
import ExportSpecialists from 'components/ExportSpecialists'
import ButtonUp from 'components/ButtonUp'
import { getClinics } from 'redux/modules/clinics/actions'

const Specialists = ({ classes }) => {
  const dispatch = useDispatch()

  const spetialists = useSelector(getSpecialistsItems)
  const isRequestsLoading = useSelector(getIsLoadingSpecialists)
  const specialistsPagination = useSelector(getSpecialistsPagination)

  const user = useSelector(getUser)

  const formik = useFormik({
    initialValues: {
      search: ''
    },
    onSubmit: (values, { setFieldError }) => {
      fetchSpecialists(1)
    }
  })

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  const fetchSpecialists = (page = specialistsPagination.page, newCount) => {
    dispatch(
      getSpecialists(
        {},
        () => {},
        () => {},
        {
          page,
          count: newCount || specialistsPagination.perPage,
          phrase: formik.values.search
        }
      )
    )
  }

  const fetchAllSpecialists = () => {
    dispatch(
      getAllSpecialists(
        {},
        () => {},
        () => {},
        {
          page: 1,
          count: 9999,
          phrase: ''
        }
      )
    )
  }

  const fetchClinics = () => {
    dispatch(
      getClinics(
        {},
        () => {},
        () => {},
        {
          page: 1,
          count: 9999,
          phrase: ''
        }
      )
    )
  }

  const fetchRegions = () => {
    dispatch(
      getRegions(
        {},
        () => {},
        () => {}
      )
    )
  }

  useEffect(() => {
    fetchSpecialists(specialistsPagination.page)
    fetchAllSpecialists()
    fetchClinics()
    fetchRegions()
  }, [])

  useEffect(() => {
    document.title = 'Специалисты'
  }, [])

  const [specialist, setSpecialist] = useState('')
  const [isOpenedEdit, setIsOpenedEdit] = useState(false)

  const openEdit = (editedSpecialist) => {
    setSpecialist(editedSpecialist)
    setIsOpenedEdit(true)
  }
  const closeEdit = () => {
    setIsOpenedEdit(false)
  }

  const [isOpenedCreateSpecialist, setIsOpenedCreateSpecialist] =
    useState(false)

  const openCreateSpecialist = () => {
    setIsOpenedCreateSpecialist(true)
  }
  const closeCreateSpecialist = () => {
    setIsOpenedCreateSpecialist(false)
  }

  const [isOpenedDelete, setIsOpenedDelete] = useState(false)
  const [deletedSpecialist, setDeletedSpecialist] = useState(null)

  const openDelete = (deletedSpecialist) => {
    setDeletedSpecialist(deletedSpecialist)
    setIsOpenedDelete(true)
  }

  const closeDelete = () => {
    setDeletedSpecialist(null)
    setIsOpenedDelete(false)
  }

  const checkStringFields = (fields) => {
    const resObj = {}
    Object.keys(fields).forEach((key) => {
      if (fields[key] === '') {
        resObj[key] = null
      } else {
        resObj[key] = fields[key]
      }
    })
    return resObj
  }

  const createSpecialist = (values, { setFieldError }) => {
    if (values.price && values.price % 200 !== 0) {
      setFieldError('price', 'Некорректное значение')
      return
    }
    setIsLoading(true)
    dispatch(
      createSpecialistAction(
        checkStringFields({
          fullName: values.fullName,
          phone: values.phone
            .replace(/\s/g, '')
            .replace(/\+/g, '')
            .replace(/-/g, ''),
          inn: values.inn,
          passport: values.passport,
          specialityId: values.speciality,
          experience: values.experience,
          portfolio: values.portfolio,
          sex: values.sex,
          degree: values.degree,
          category: values.category,
          price: values.price,
          isDuty: values.isDuty,
          isSpecial: values.isSpecial,
          descriptionRu: values.descriptionRu,
          descriptionKg: values.descriptionKg,
          medicalInstitutionId: values.medicalInstitution,
          regionId: values.regionId
        }),
        (data) => {
          if (!values.photo || typeof values.photo === 'string') {
            fetchSpecialists(specialistsPagination.page)
            closeCreateSpecialist()
            closeEdit()
            setIsLoading(false)
          } else {
            editSpecialistMedia(data.id, values.photo)
          }
        },
        (error) => {
          if (error.status === 403) {
            setFieldError(error.description, 'Такоe значение уже существует')
          }
          setIsLoading(false)
          setError(true)
        }
      )
    )
  }

  const editSpecialistMedia = (id, value) => {
    const formData = new FormData()
    formData.append('photo', value)

    dispatch(
      uploadMedia(
        { id: id, photo: formData },
        (data) => {
          fetchSpecialists(specialistsPagination.page)
          closeCreateSpecialist()
          closeEdit()
          setIsLoading(false)
        },
        () => {
          setIsLoading(false)
        }
      )
    )
  }

  const editSpecialist = (
    isOpenedPortfolio,
    setIsOpenedPortfolio,
    values,
    { setFieldError }
  ) => {
    console.log('мы тут')

    if (values.price && values.price % 200 !== 0) {
      setFieldError('price', 'Некорректное значение')
      return
    }
    setIsLoading(true)
    dispatch(
      editSpecialistAction(
        checkStringFields({
          id: specialist.id,
          fullName: values.fullName,
          phone: values.phone
            .replace(/\s/g, '')
            .replace(/\+/g, '')
            .replace(/-/g, ''),
          inn: values.inn,
          passport: values.passport,
          specialities:
            values.specialities.length > 0
              ? values.specialities[0].title
                ? values.specialities.map((specialitiy) => specialitiy.id)
                : values.specialities
              : values.specialities,
          experience: values.experience,
          portfolio: {
            education: values.typeEducation
              ? [
                  ...values.portfolio.education,
                  {
                    typeEducation: values.typeEducation,
                    institution: values.institution,
                    speciality: values.specialityEducation,
                    yearGraduated: values.yearGraduated
                  }
                ]
              : [...values.portfolio.education],
            workExperience: values.placeOfWork
              ? [
                  ...values.portfolio.workExperience,
                  {
                    placeOfWork: values.placeOfWork,
                    position: values.position,
                    startDate: values.startDate,
                    endDate: values.endDate
                  }
                ]
              : [...values.portfolio.workExperience],
            courses: values.courseName
              ? [
                  ...values.portfolio.courses,
                  { year: values.year, courseName: values.courseName }
                ]
              : [...values.portfolio.courses]
          },
          sex: values.sex,
          degree: values.degree,
          category: values.category,
          isActive: values.isActive,
          isDuty: values.isDuty,
          descriptionRu: values.descriptionRu,
          descriptionKg: values.descriptionKg,
          price: values.price,
          medicalInstitutionId: values.medicalInstitution,
          regionId: values.regionId,
          parentSpecialistId: values.parentSpecialist
        }),
        (data) => {
          if (!values.photo || typeof values.photo === 'string') {
            fetchSpecialists(specialistsPagination.page)
            if (isOpenedPortfolio) {
              editPortfolio(data, setIsOpenedPortfolio)
            } else {
              closeCreateSpecialist()
              closeEdit()
            }
            setIsLoading(false)
          } else {
            editSpecialistMedia(data.id, values.photo)
          }
        },
        (error) => {
          if (error.status === 403) {
            setFieldError(error.description, 'Такоe значение уже существует')
          }
          setIsLoading(false)
          setError(true)
        }
      )
    )
  }

  const editPortfolio = (values, setIsOpenedPortfolio) => {
    dispatch(
      editSpecialistAction(
        {
          id: specialist.id,
          fullName: values.fullName,
          phone: values.phone
            .replace(/\s/g, '')
            .replace(/\+/g, '')
            .replace(/-/g, ''),
          inn: values.inn,
          passport: values.passport,
          specialities:
            values.specialities.length > 0
              ? values.specialities[0].title
                ? values.specialities.map((specialitiy) => specialitiy.id)
                : values.specialities
              : values.specialities,
          experience: values.experience,
          portfolio: {
            education: values.typeEducation
              ? [
                  ...values.portfolio.education,
                  {
                    typeEducation: values.typeEducation,
                    institution: values.institution,
                    speciality: values.specialityEducation,
                    yearGraduated: values.yearGraduated
                  }
                ]
              : [...values.portfolio.education],
            workExperience: values.placeOfWork
              ? [
                  ...values.portfolio.workExperience,
                  {
                    placeOfWork: values.placeOfWork,
                    position: values.position,
                    startDate: values.startDate,
                    endDate: values.endDate
                  }
                ]
              : [...values.portfolio.workExperience],
            courses: values.courseName
              ? [
                  ...values.portfolio.courses,
                  { year: values.year, courseName: values.courseName }
                ]
              : [...values.portfolio.courses]
          },
          sex: values.sex,
          degree: values.degree,
          category: values.category,
          isActive: values.isActive
          // parentSpecialistId: values.parentSpecialist.id ?? null
        },
        (data) => {
          fetchSpecialists(1)
          setIsOpenedPortfolio(false)
        },
        (error) => {
          if (error.status === 403) {
            // setFieldError(error.description, 'Такоe значение уже существует')
          }
          setIsLoading(false)
          setError(true)
        }
      )
    )
  }

  const deleteSpecialist = () => {
    dispatch(
      deleteSpecialistAction(
        { id: deletedSpecialist.id },
        () => {
          fetchSpecialists(1)
          setIsOpenedDelete(false)
        },
        () => {}
      )
    )
  }

  const createTableValue = (values, close) => {
    dispatch(
      editSpecialistAction(
        {
          id: specialist.id,
          portfolio: {
            education: values.values.typeEducation
              ? [
                  ...values.values.portfolio.education,
                  {
                    typeEducation: values.values.typeEducation,
                    institution: values.values.institution,
                    speciality: values.values.specialityEducation,
                    yearGraduated: values.values.yearGraduated
                  }
                ]
              : [...values.values.portfolio.education],
            workExperience: values.values.placeOfWork
              ? [
                  ...values.values.portfolio.workExperience,
                  {
                    placeOfWork: values.values.placeOfWork,
                    position: values.values.position,
                    startDate: values.values.startDate,
                    endDate: values.values.endDate
                  }
                ]
              : [...values.values.portfolio.workExperience],
            courses: values.values.courseName
              ? [
                  ...values.values.portfolio.courses,
                  {
                    year: values.values.year,
                    courseName: values.values.courseName
                  }
                ]
              : [...values.values.portfolio.courses]
          }
        },
        (data) => {
          fetchSpecialists(1)
          values.setFieldValue('portfolio', data.portfolio)
          values.setFieldValue('typeEducation', '')
          values.setFieldValue('institution', '')
          values.setFieldValue('specialityEducation', '')
          values.setFieldValue('yearGraduated', '')
          values.setFieldValue('placeOfWork', '')
          values.setFieldValue('position', '')
          values.setFieldValue('startDate', '')
          values.setFieldValue('endDate', '')
          values.setFieldValue('year', '')
          values.setFieldValue('courseName', '')
          close()
        },
        () => {}
      )
    )
  }

  const editTableValue = (values, deletedEducation, type, close) => {
    let newArray = [...values.values.portfolio[type]]
    if (type === 'education') {
      newArray = [
        ...values.values.portfolio[type],
        {
          institution: values.values.institution,
          speciality: values.values.specialityEducation,
          typeEducation: values.values.typeEducation,
          yearGraduated: values.values.yearGraduated
        }
      ]
    } else if (type === 'courses') {
      newArray = [
        ...values.values.portfolio[type],
        {
          courseName: values.values.courseName,
          year: values.values.year
        }
      ]
    } else {
      newArray = [
        ...values.values.portfolio[type],
        {
          placeOfWork: values.values.placeOfWork,
          position: values.values.position,
          startDate: values.values.startDate,
          endDate: values.values.endDate
        }
      ]
    }
    newArray.splice(deletedEducation, 1)

    dispatch(
      editSpecialistAction(
        {
          id: specialist.id,
          portfolio: {
            education:
              type == 'education'
                ? newArray
                : [...values.values.portfolio.education],
            workExperience:
              type == 'workExperience'
                ? newArray
                : [...values.values.portfolio.workExperience],
            courses:
              type == 'courses'
                ? newArray
                : [...values.values.portfolio.courses]
          }
        },
        (data) => {
          fetchSpecialists(specialistsPagination.page)
          values.setFieldValue('portfolio', data.portfolio)
          close()
          // closeEdit()
        },
        () => {}
      )
    )
  }

  const deleteTableValue = (values, deletedEducation, type, closeDelete) => {
    let newArray = [...values.values.portfolio[type]]
    newArray.splice(deletedEducation, 1)

    dispatch(
      editSpecialistAction(
        {
          id: specialist.id,
          portfolio: {
            education:
              type == 'education'
                ? newArray
                : [...values.values.portfolio.education],
            workExperience:
              type == 'workExperience'
                ? newArray
                : [...values.values.portfolio.workExperience],
            courses:
              type == 'courses'
                ? newArray
                : [...values.values.portfolio.courses]
          }
        },
        (data) => {
          fetchSpecialists(specialistsPagination.page)
          values.setFieldValue('portfolio', data.portfolio)
          closeDelete()
        },
        () => {}
      )
    )
  }

  const [isOpenedExport, setIsOpenedExport] = useState(false)

  const onClickExport = () => {
    setIsOpenedExport(true)
  }

  const closeExport = () => {
    setIsOpenedExport(false)
  }

  const [isOpenedDeleteSignature, setIsOpenedDeleteSignature] = useState(false)
  const [deletedSpecialistSignature, setDeletedSpecialistSignature] =
    useState(null)

  const openDeleteSignature = (deletedSpecialist) => {
    setDeletedSpecialistSignature(deletedSpecialist)
    setIsOpenedDeleteSignature(true)
  }

  const closeDeleteSignature = () => {
    setDeletedSpecialistSignature(null)
    setIsOpenedDeleteSignature(false)
  }

  const deleteSpecialistSignature = () => {
    dispatch(
      deleteSpecialistSignatureAction(
        { id: deletedSpecialistSignature.id },
        () => {
          fetchSpecialists(1)
          setIsOpenedDeleteSignature(false)
        },
        () => {}
      )
    )
  }

  console.log('spetialists', spetialists)

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.wrapper}>
        <Menu />
        <div className={classes.table}>
          <div className={classes.search}>
            <ControlBlockExport
              formik={formik}
              searchText={'Найти'}
              exportText={'Экспорт работы за период'}
              fieldName={'search'}
              onClickExport={onClickExport}
            />
          </div>
          {spetialists.length > 0 || isRequestsLoading ? (
            <>
              <div className={classes.tableWrapper}>
                <TableWrapper
                  page={specialistsPagination.page}
                  count={specialistsPagination.perPage}
                  amount={specialistsPagination.amount}
                  items={spetialists}
                  onChangePage={fetchSpecialists}
                  onChangePerPage={(newCount) => fetchSpecialists(1, newCount)}
                  isLoading={isRequestsLoading}
                  columns={columnsSpecialists}
                  tableType={'specialists'}
                  actions={{
                    editSpecialistsName: openEdit,
                    openDelete: openDelete,
                    openDeleteSignature: openDeleteSignature
                  }}
                />
              </div>
              {user && user.status !== 'call' && (
                <div className={classes.button}>
                  <ButtonCreate
                    openCreate={openCreateSpecialist}
                    text="Добавить специалиста"
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <NoContent text={'Нет специалистов'} />
              {user && user.status !== 'call' && (
                <div className={classes.button}>
                  <ButtonCreate
                    openCreate={openCreateSpecialist}
                    text="Добавить специалиста"
                  />
                </div>
              )}
            </>
          )}
        </div>
        <ModalWrapper
          title={'Вы уверены?'}
          isOpened={isOpenedDelete}
          onClose={() => closeDelete()}
        >
          <Delete
            onDelete={() => deleteSpecialist()}
            onClose={() => closeDelete()}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Вы уверены?'}
          isOpened={isOpenedDeleteSignature}
          onClose={() => closeDeleteSignature()}
        >
          <Delete
            onDelete={() => deleteSpecialistSignature()}
            onClose={() => closeDeleteSignature()}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Карточка специалиста'}
          isOpened={isOpenedCreateSpecialist}
          onClose={() => closeCreateSpecialist()}
          style={{ width: '800px' }}
        >
          <Specialist
            handleSubmit={createSpecialist}
            isLoading={isLoading}
            error={error}
            setError={setError}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Карточка специалиста'}
          isOpened={isOpenedEdit}
          onClose={() => closeEdit()}
          style={{ width: '800px' }}
        >
          <Specialist
            handleSubmit={editSpecialist}
            editedObj={specialist}
            setEditedObj={setSpecialist}
            deleteTableValue={deleteTableValue}
            editTableValue={editTableValue}
            createTableValue={createTableValue}
            isLoading={isLoading}
            error={error}
            setError={setError}
            editPortfolio={editPortfolio}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Экспорт в XLS'}
          isOpened={isOpenedExport}
          onClose={() => closeExport()}
          style={{ width: '800px' }}
        >
          <ExportSpecialists />
        </ModalWrapper>
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex'
  },
  table: {
    width: '100%',
    padding: '1em 0'
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  tableWrapper: {
    padding: '0 2em'
  },
  button: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
})

export default withStyles(styles)(Specialists)
